//all the actions used in this app
import { ActionTypes } from "../types/Redux";

export const nextQuestion = () => {
  return {
    type: ActionTypes.NEXT_QUESTION,
    payload: {
      amount: 1,
    },
  };
};

export const prevQuestion = () => {
  return {
    type: ActionTypes.PREV_QUESTION,
    payload: {
      amount: 1,
    },
  };
};

export const updateQuestion = (id: number, points: number) => {
  return {
    type: ActionTypes.UPDATE_POINTS,
    payload: {
      id,
      points,
    },
  };
};
