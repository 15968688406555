import styled from "styled-components";
import { MainButton } from "../../global/GlobalStyles";
import { ScreenSize } from "../../types/Enums";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.div`
  width: 30rem;
  font-size: 1.5rem;
  margin: 4rem auto 3rem;
  text-align: center;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    text-align: left;
    width: 20rem;
    margin: 3rem auto;
  }
`;

export const Time = styled.div`
  margin: 0 auto 2rem;
`;

export const StartButton = styled(MainButton)`
  width: 14rem;
  font-size: 1rem;
  padding: 0.5rem 6rem;
  margin-bottom: 2rem;
`;

export const UpsalesForm = styled.iframe`
  margin-bottom: 4rem;
  border: 0;
  width: 360px;
  height: 515px;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    margin-bottom: 0;
    width: 320px;
  }
`;
