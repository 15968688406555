import { IQuestions } from "./Interfaces";

export const SurveyChoices = [
  { text: "Inte alls", value: 0.1 },
  { text: "Lite", value: 1.3 },
  { text: "Ibland", value: 2.5 },
  { text: "Ofta", value: 3.7 },
  { text: "Absolut", value: 4.9 },
];

export const ButtonText = {
  START: "Start",
  WHITEPAPER: "Läs mer här",
  BACKWARD: "<<",
  FORWARD: ">>",
  REDOTEST: "Testa igen",
};

export const Links = {
  LEMONTREE_EMAIL: "info@lemontree.se",
  SIMON_EMAIL: "simon.hjort@lemontree.se",
  WHITEPAPER:
    "https://lemontree.se/wp-content/uploads/2020/05/continuous-maturity-model-v2.pdf",
};

export const Questions: IQuestions[] = [
  {
    id: 0,
    question:
      "Inför en release: Funkar samarbetet och kommunikationen idag mellan de olika teamen på bolaget? Vet alla vem man vänder sig till om något går fel? Stämmer det att ingen behöver jobba övertid?",
    points: 0,
  },
  {
    id: 1,
    question:
      "Inför en release: Är bolaget bra på att konstant hålla produkten i releasebar skick? Stämmer det att ni väldigt ofta håller era deadlines?",
    points: 0,
  },
  {
    id: 2,
    question:
      "Om en kritisk defekt skulle rapporteras in av en slutanvändare, utförs en gemensam självrannsakan av de berörda teamen för att se om det finns förbättringsåtgärder att ta gällande kommunikation, arbetssätt mm",
    points: 0,
  },
  {
    id: 3,
    question:
      "Medarbetarna får budgeterat kompetensutveckling årligen och materialet blir tillgänglig för organisationen mha dedikerade verktyg (tex Sharepoint).",
    points: 0,
  },
  {
    id: 4,
    question:
      "Medarbetarna engagerar sig i en eller flera teamöverbryggande kompetensgrupper där de utbyter erfarenheter, anordnar kurser och bjuder in externa föreläsare.",
    points: 0,
  },
];

export const ContentText = {
  startPage: {
    WELCOME:
      "Välkomna till Lemontrees verktyg för att mäta vilken DevOps mognad ditt företag har!",
  },
  resultPage: {
    RESULT_LOW: "Lågt",
    RESULT_MID: "Medel",
    RESULT_HIGH: "Högt!",
    HEADING_RIGHT: {
      LOW: "Hoppsan, det verkar som att Ni kan behöva lite hjälp att komma igång med DevOps-arbetet",
      MID: "Ni verkar ha kommit en bit på väg i DevOps-arbetet",
      HIGH: "Oj, det ser ut som att Ni ligger i framkant med DevOps-arbetet",
    },
    TEXT_RIGHT: {
      LOW: "Vi kan bistå med att hitta era styrkor respektive de områden där vi ser störst förbättringspotential för att snabbt komma framåt",
      MID: "Vi hjälper dig gärna att ta fram en mer nyanserad bild av nuläget och identifiera områden med störst förbättringspotential",
      HIGH: "Vi hjälper dig gärna att identifiera områden med störst förbättringspotential",
    },
    CONTACT_U_TEXT: "Vi hör av oss inom kort!",
    TEXT_BOX:
      "Vill du veta vad en bättre DevOps-mognad kan innebära för ditt företag?",
    CONTACT_US: "Simon Hjort",
    STREET: "Sveavägen 52",
    ZIP: "111 32, Stockholm",
    TEL: "+46 70 145 57 70",
    MODAL_TEXT: "Är du säker? Dina tidigare val kommer att raderas",
    MODAL_YES: "Ja",
    MODAL_NO: "Nej",
  },
};
