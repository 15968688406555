//reducer that decides what happens to the question counter state depending on action
import { ActionTypes, QuestionAction } from "../types/Redux";

const questionCounter = (state = 0, action: QuestionAction) => {
  switch (action.type) {
    case ActionTypes.NEXT_QUESTION:
      return state + action.payload.amount;
    case ActionTypes.PREV_QUESTION:
      return state - action.payload.amount;
    default:
      return state;
  }
};
export default questionCounter;
