import { useNavigate } from "react-router-dom";
import { questionsLocalStorageName } from "../../types/Redux";
import * as S from "./Modal.styles";
import { ContentText } from "../../types/Copy";
import { Colors } from "../../global/GlobalStyles";
import { Paths } from "../../types/Enums";

interface IModalState {
  handleNoClick: () => void;
}

const Modal = ({ handleNoClick }: IModalState) => {
  const navigate = useNavigate();

  const yesClick = () => {
    let localExist = localStorage.getItem(questionsLocalStorageName);
    if (localExist?.length != null) {
      localStorage.removeItem(questionsLocalStorageName);
    }
    navigate(Paths.HOME);
  };

  const noClick = () => {
    handleNoClick();
  };

  return (
    <S.Main>
      <S.ModalContent>
        {ContentText.resultPage.MODAL_TEXT}
        <br />
        <S.ButtonsRow>
          <S.ChoiceButton
            style={{ backgroundColor: Colors.bgGreen }}
            onClick={() => yesClick()}
          >
            {ContentText.resultPage.MODAL_YES}
          </S.ChoiceButton>
          <S.ChoiceButton
            style={{ backgroundColor: Colors.bgRed }}
            onClick={() => noClick()}
          >
            {ContentText.resultPage.MODAL_NO}
          </S.ChoiceButton>
        </S.ButtonsRow>
      </S.ModalContent>
    </S.Main>
  );
};
export default Modal;
