import styled from "styled-components";
import { Colors, MainButton } from "../../global/GlobalStyles";
import { ScreenSize } from "../../types/Enums";

export const LeftAndRight = styled.div`
  display: flex;
  position: relative;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  width: 50%;
  border-right: 1px solid ${Colors.grey};
  margin: 4rem 0;
  flex-direction: column;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 100%;
    border: none;
    margin: 0;
  }
`;
export const RightSide = styled.div`
  display: flex;
  width: 50%;
  margin: 4rem 0;
  flex-direction: column;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 100%;
    margin: 0;
  }
`;

export const Result = styled.div`
  font-size: 3rem;
  color: ${Colors.grey};
  margin: 5rem auto 0;
  display: flex;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 2rem;
    margin: 3rem auto 0;
  }
`;

export const BarometerContainer = styled.div`
  width: 62.5%;
  height: 8rem;
  border-left: 3px solid ${Colors.strongOrange};
  border-right: 3px solid ${Colors.strongOrange};
  margin: 6rem auto 0;
  position: relative;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 85%;
    margin: 3rem auto 0;
  }
`;

export const BarometerBg = styled.div`
  height: 2rem;
  background-color: ${Colors.lightGrey};
  width: 100%;
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 10;
`;

interface IBarometer {
  percentage: number;
}

export const Barometer = styled.div<IBarometer>`
  height: 2rem;
  background-color: ${Colors.strongOrange};
  width: ${(props) => props.percentage}%;
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 20;
`;

export const BarometerPointer = styled.div<IBarometer>`
  position: absolute;
  left: ${(props) => props.percentage}%;
  top: 1rem;
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 2rem solid ${Colors.grey};
  transform: translate(-50%, 0);
`;

export const BarometerNumber = styled.p<IBarometer>`
  font-size: 0.875rem;
  letter-spacing: 5%;
  text-align: center;
  position: absolute;
  bottom: -2.5rem;
  left: ${(props) => props.percentage}%;
  transform: translate(-50%, 0);
`;

export const LeftText = styled.p`
  margin: 7rem auto 3rem;
  font-size: 2rem;
  text-align: center;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    display: none;
  }
`;

export const RightHeading = styled.p`
  margin: 5rem auto 0;
  font-size: 2.5rem;
  letter-spacing: 0.05em;
  text-align: center;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1.5rem;
  }
`;

export const RightResultText = styled.p`
  text-align: center;
  padding: 0 2rem;
  font-size: 18px;
  letter-spacing: 0.05em;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1rem;
    letter-spacing: 0;
  }
`;

export const GetInTouchText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  background-color: ${Colors.strongOrange};
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
`;

export const InfoBox = styled.div`
  background-color: ${Colors.lightGrey};
  padding: 2rem;
  text-align: center;
  margin-top: 5rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    margin-top: 5rem;
  }
`;

export const BoxText = styled.p`
  font-size: 1.25rem;
  margin: 0 auto 2rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 2rem 2rem;
  }
`;

export const BoxButton = styled(MainButton)`
  padding: 1rem 2rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.borderGrey};
  box-shadow: none;
  font-size: 1.25rem;
  &:hover {
    cursor: pointer;
    color: ${Colors.grey};
    background-color: ${Colors.strongOrange};
  }
  & a {
    text-decoration: none;
    color: ${Colors.black};
  }
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1rem;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 60%;
  align-items: end;
  align-self: center;
  margin: 4rem 0 2rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    justify-content: flex-start;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const ContactsBox = styled.div`
  font-size: 1.25rem;
  text-align: left;
  margin: 0 2rem 0 0;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    text-align: center;
    margin: 1rem 0 0 0;
  }
`;

export const ContactsText = styled.p`
  line-height: 2rem;
  margin: 0;
`;

export const ContactImage = styled.img`
  height: 10rem;
  border-radius: 50%;
`;

export const logo = styled.img`
  position: absolute;
  right: 1rem;
  bottom: 4.5rem;
  width: 14rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    position: static;
    display: block;
    margin: 3rem auto 2rem;
    width: 9rem;
  }
`;

export const ResetRestartButton = styled(MainButton)`
  width: 10rem;
  align-self: center;
  margin-top: 6rem;
  font-size: 1rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    margin: 4rem auto 0;
  }
`;