import { useNavigate } from "react-router-dom";
import { MainContainer } from "../../global/GlobalStyles";
import { ContentText, Questions } from "../../types/Copy";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import * as S from "./Home.styles";
import { Paths } from "../../types/Enums";

const Home = () => {
  const totalTime = Math.floor((Questions.length * 30) / 60);
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Header />
      <S.Main>
        <S.Heading>{ContentText.startPage.WELCOME}</S.Heading>
        <S.Time>⏱️ {totalTime}min</S.Time>
        {/* <S.UpsalesForm
          src="https://pages.upsales.com/302u2610371ff25848919d2ef9c9c2d3260e-frame"
          title="upsales"
        ></S.UpsalesForm> */}
        <S.StartButton onClick={() => navigate(Paths.SURVEY)}>
          Start
        </S.StartButton>
      </S.Main>
      <Footer />
    </MainContainer>
  );
};

export default Home;
