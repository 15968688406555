import * as S from "./Header.styles";

const Header = () => {
  return (
    <S.Main>
      Devops Teaser
      <S.headingImg src="/images/DevOps.jpg" alt="devops-img" />
    </S.Main>
  );
};

export default Header;
