import styled from "styled-components";
import { Colors, Opacity } from "../../global/GlobalStyles";
import { ScreenSize } from "../../types/Enums";

export const Main = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    position: relative;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: ${Colors.black}${Opacity._60};
  background-color: ${Colors.strongOrange};
  height: 4rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1rem;
    height: 5rem;
    margin-right: 0;
  }
`;

export const logo = styled.img`
  display: none;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 9rem;
    margin: 3rem auto 0.5rem;
    display: block;
  }
`;

export const LogoWhite = styled.img`
  position: absolute;
  width: 12rem;
  top: 1rem;
  right: 1rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    display: none;
  }
`;
