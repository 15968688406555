import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Result from "./components/result/Result";
import Survey from "./components/survey/Survey";
import { Paths } from "./types/Enums";

function App() {
  return (
    <div>
      <Routes>
        <Route path={Paths.HOME} element={<Home />} />
        <Route path={Paths.RESULT} element={<Result />} />
        <Route path={Paths.SURVEY} element={<Survey />} />
      </Routes>
    </div>
  );
}

export default App;
