import styled, { keyframes } from "styled-components";
import { Colors, Opacity } from "../../global/GlobalStyles";
import { ScreenSize } from "../../types/Enums";

const ModalAnimation = keyframes`
 0% { height: 0; width: 100vw; }
 50% { height: 100vh; width: 100vw; }
`;

export const Main = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: ${Colors.black}${Opacity._80};
  z-index: 30;
  animation-name: ${ModalAnimation};
  animation-duration: 3s;
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 1rem 1.5rem;
  border-radius: 3px;
  max-width: 600px;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    left: 50%;
    text-align: center;
    width: 16rem;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
`;

export const ChoiceButton = styled.button`
  width: 3rem;
  height: 2rem;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
`;
