import { useDispatch, useSelector } from "react-redux";
import { nextQuestion, prevQuestion, updateQuestion } from "../../actions";
import { MainContainer } from "../../global/GlobalStyles";
import { ButtonText, Questions, SurveyChoices } from "../../types/Copy";
import { IQuestions } from "../../types/Interfaces";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import * as S from "./Survey.styles";
import { useNavigate } from "react-router";
import { Paths } from "../../types/Enums";
import { questionsLocalStorageName } from "../../types/Redux";
import { RootState } from "../../store";

const Survey = () => {
  const navigate = useNavigate();

  //the dispatch hook updates the global values depending on action
  const dispatch = useDispatch();

  //useSelector gets the global states that are updated using dispatch
  const currQuestionId = useSelector<RootState, number>(
    (state) => state.questionCounter
  );
  const questions = useSelector<RootState, IQuestions[]>(
    (state) => state.updateQuestionPoints.questions
  );

  const currentPoint = (id: number) => {
    const matchingQuestion = questions.find((q) => q.id === id);
    return matchingQuestion ? matchingQuestion.points : 0;
  };

  const handleForwardClick = () => {
    localStorage.setItem(questionsLocalStorageName, JSON.stringify(questions));
    if (currQuestionId + 1 === Questions.length) {
      navigate(Paths.RESULT);
    } else {
      dispatch(nextQuestion());
    }
  };

  return (
    <MainContainer>
      <Header />
      <S.Main>
        <S.SurveyArea>
          <S.Question>
            <S.QuestionHeading>
              Fråga {currQuestionId + 1} av {Questions.length}:{" "}
            </S.QuestionHeading>
            {Questions[currQuestionId].question}
          </S.Question>
          <S.SurveyButtons>
            {SurveyChoices.map((choice, i) => {
              return (
                <S.ChoiceButton
                  key={i}
                  isCurrentChoice={
                    currentPoint(currQuestionId) === choice.value
                  }
                  onClick={() => {
                    dispatch(updateQuestion(currQuestionId, choice.value));
                    handleForwardClick();
                  }}
                >
                  {choice.text}
                </S.ChoiceButton>
              );
            })}
          </S.SurveyButtons>
          <S.DirectionButtons userAtFirstQuestion={currQuestionId > 0}>
            <S.BackButton
              userAtFirstQuestion={currQuestionId > 0}
              onClick={() => dispatch(prevQuestion())}
            >
              {ButtonText.BACKWARD}
            </S.BackButton>
            <S.FwdButton
              choiceSelected={currentPoint(currQuestionId) > 0}
              onClick={() => handleForwardClick()}
            >
              {ButtonText.FORWARD}
            </S.FwdButton>
          </S.DirectionButtons>
        </S.SurveyArea>
        <S.PicArea>
          <S.Image
            src="../../images/imageedit_18_6990270873 1.png"
            alt="Lemontree logo"
          />
        </S.PicArea>
      </S.Main>
      <Footer />
    </MainContainer>
  );
};

export default Survey;
