//reducer that updates the question points based on id
import { Questions } from "../types/Copy";
import {
  ActionTypes,
  questionsLocalStorageName,
  UpdatePointsAction,
} from "../types/Redux";

const initialQuestions = localStorage[questionsLocalStorageName]
  ? JSON.parse(localStorage[questionsLocalStorageName])
  : Questions;

const initialState = { questions: initialQuestions };

const updateQuestionPoints = (
  state = initialState,
  action: UpdatePointsAction
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_POINTS:
      const { id, points } = action.payload;
      const updatedQuestions = state.questions.map((q: { id: number }) => {
        if (q.id === id) {
          return { ...q, points };
        }
        return q;
      });
      return { ...state, questions: updatedQuestions };
    default:
      return state;
  }
};
export default updateQuestionPoints;
