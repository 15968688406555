import styled, { createGlobalStyle } from "styled-components";
import { ScreenSize } from "../types/Enums";

const FontStyles = createGlobalStyle`
body{
    font-family: 'Mansalva', cursive;
    font-family: 'Roboto Condensed', sans-serif;
}
`;

export const Colors = {
  grey: "#666D70",
  strongOrange: "#FCB924",
  lightGrey: "#f5f5f5",
  darkBlue: "#4061e5",
  white: "#FFFFFF",
  borderGrey: "#d9d9d9",
  black: "#000000",
  bgRed: "#f4a4a4",
  bgGreen: "#a0fb8e",
};

export const Opacity = {
  _0: "00",
  _5: "0C",
  _10: "19",
  _15: "26",
  _20: "33",
  _25: "3F",
  _30: "4C",
  _35: "59",
  _40: "66",
  _45: "72",
  _50: "7F",
  _55: "8C",
  _60: "99",
  _65: "A5",
  _70: "B2",
  _75: "BF",
  _80: "CC",
  _85: "D8",
  _90: "E5",
  _95: "F2",
  _100: "FF",
};

export const MainContainer = styled.div`
  margin: 0 auto;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 100%;
  }
`;

export const MainButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${Colors.lightGrey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  width: fit-content;
  font-family: "Mansalva", cursive;
  font-family: "Roboto Condensed", sans-serif;
  &:hover {
    background-color: ${Colors.strongOrange};
    cursor: pointer;
  }
`;

export const Email = styled.a`
  font-size: 1.25rem;
  color: ${Colors.darkBlue};
  text-decoration: none;
  cursor: pointer;
`;

export default FontStyles;
