import styled from "styled-components";
import { Colors } from "../../global/GlobalStyles";
import { ScreenSize } from "../../types/Enums";

export const Main = styled.div`
  display: flex;
  align-items: center;
  font-size: 3.75rem;
  color: ${Colors.black};
  background-color: ${Colors.strongOrange};
  padding: 0 2rem;
  height: 8rem;
  justify-content: space-between;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1.25rem;
    padding: 0 0.5rem;
    height: 5rem;
  }
`;

export const headingImg = styled.img`
  height: 8rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    height: 4rem;
  }
`;
