import * as S from "./Footer.styles";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  console.log(process.env);
  return (
    <S.Main>
      <S.logo alt="" src="images/lemontree_logo_orange.png" />
      <S.TextDiv>
        {process.env.REACT_APP_BRANCH === "staging"
          ? "STAGING"
          : "© Copyright Lemontree " + currentYear}
      </S.TextDiv>
      <S.LogoWhite alt="" src="images/lemontree_logo_white.png" />
    </S.Main>
  );
};

export default Footer;
