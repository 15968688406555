import { MainContainer, Email } from "../../global/GlobalStyles";
import {
  ButtonText,
  ContentText,
  Links,
  Questions,
  SurveyChoices,
} from "../../types/Copy";
import { questionsLocalStorageName } from "../../types/Redux";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import * as S from "./Result.styles";
import Modal from "../modal/Modal";
import { useState } from "react";

const Result = () => {
  const [showModal, setShowModal] = useState(false);

  const questions = localStorage[questionsLocalStorageName]
    ? JSON.parse(localStorage[questionsLocalStorageName])
    : Questions;

  const totalPoints = questions.reduce(
    (tot: number, arr: { points: number }) => {
      return tot + arr.points;
    },
    0
  );
  const maxPoints = questions.length * SurveyChoices.length;
  const percentagePoints = Math.round((totalPoints / maxPoints) * 100);

  let pointsDegree,
    headingRight,
    textRight = "";

  switch (true) {
    case percentagePoints <= 20:
      pointsDegree = ContentText.resultPage.RESULT_LOW;
      headingRight = ContentText.resultPage.HEADING_RIGHT.LOW;
      textRight = ContentText.resultPage.TEXT_RIGHT.LOW;
      break;
    case percentagePoints <= 60:
      pointsDegree = ContentText.resultPage.RESULT_MID;
      headingRight = ContentText.resultPage.HEADING_RIGHT.MID;
      textRight = ContentText.resultPage.TEXT_RIGHT.MID;
      break;
    default:
      pointsDegree = ContentText.resultPage.RESULT_HIGH;
      headingRight = ContentText.resultPage.HEADING_RIGHT.HIGH;
      textRight = ContentText.resultPage.TEXT_RIGHT.HIGH;
      break;
  }

  return (
    <div>
      <MainContainer>
        <Header />
        {showModal && <Modal handleNoClick={() => setShowModal(false)} />}
        <S.LeftAndRight>
          <S.LeftSide>
            <S.Result>
              {pointsDegree}&nbsp;({percentagePoints}%)
            </S.Result>
            <S.BarometerContainer>
              <S.BarometerBg />
              <S.Barometer percentage={percentagePoints} />
              <S.BarometerPointer percentage={percentagePoints} />
              <S.BarometerNumber percentage={0}>0%</S.BarometerNumber>
              <S.BarometerNumber percentage={100}>100%</S.BarometerNumber>
            </S.BarometerContainer>
            <S.ResetRestartButton onClick={() => setShowModal(true)}>
              {ButtonText.REDOTEST}
            </S.ResetRestartButton>
          </S.LeftSide>
          <S.RightSide>
            <S.RightHeading>{headingRight}</S.RightHeading>
            <S.RightResultText>{textRight}</S.RightResultText>
            <S.GetInTouchText>
              {ContentText.resultPage.CONTACT_U_TEXT}
            </S.GetInTouchText>
            <S.InfoBox>
              <S.BoxText>{ContentText.resultPage.TEXT_BOX}</S.BoxText>
              <S.BoxButton>
                <a href={Links.WHITEPAPER} target="_blank" rel="noreferrer">
                  {ButtonText.WHITEPAPER}
                </a>
              </S.BoxButton>
            </S.InfoBox>
            <S.ContactContainer>
              <S.ContactsBox>
                <S.ContactsText style={{ fontWeight: "bold" }}>
                  {ContentText.resultPage.CONTACT_US}
                </S.ContactsText>
                <S.ContactsText>{ContentText.resultPage.STREET}</S.ContactsText>
                <S.ContactsText>{ContentText.resultPage.ZIP}</S.ContactsText>
                <S.ContactsText>{ContentText.resultPage.TEL}</S.ContactsText>
                <S.ContactsText>
                  <Email href={`mailto:${Links.LEMONTREE_EMAIL}`}>
                    {Links.LEMONTREE_EMAIL}
                  </Email>
                </S.ContactsText>
              </S.ContactsBox>
              <S.ContactImage src="/images/simon.jpg" alt="simon-img" />
            </S.ContactContainer>
          </S.RightSide>
        </S.LeftAndRight>
        <Footer />
      </MainContainer>
    </div>
  );
};

export default Result;
