export enum Paths {
  HOME = "/",
  SURVEY = "/survey",
  RESULT = "/result",
}

export enum ScreenSize {
  MOBILE = "480px",
  WEB = "1920px",
}
