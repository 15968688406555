//enums, types and interfaces used for redux
export enum ActionTypes {
  NEXT_QUESTION = "NEXT_QUESTION",
  PREV_QUESTION = "PREV_QUESTION",
  UPDATE_POINTS = "UPDATE_POINTS",
}

interface NextQuestionAction {
  type: ActionTypes.NEXT_QUESTION;
  payload: {
    amount: number;
  };
}

interface PrevQuestionAction {
  type: ActionTypes.PREV_QUESTION;
  payload: {
    amount: number;
  };
}

export type QuestionAction = NextQuestionAction | PrevQuestionAction;

export type UpdatePointsAction = {
  type: ActionTypes.UPDATE_POINTS;
  payload: {
    id: number;
    points: number;
  };
};

export const questionsLocalStorageName = "DMTResult";
