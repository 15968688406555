import styled from "styled-components";
import { Colors, MainButton } from "../../global/GlobalStyles";
import { ScreenSize } from "../../types/Enums";

export const Main = styled.div`
  display: flex;
  margin: 2rem 0;
  align-items: center;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    display: unset;
    margin: 0;
  }
`;

export const Question = styled.div`
  width: auto;
  font-size: 1.5rem;
  margin: 0 auto;
  text-align: left;
  padding: 0 2rem;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    padding: 0;
    margin: 3rem auto 0;
    font-size: 1.25rem;
  }
`;

export const QuestionHeading = styled.div`
  font-weight: bold;
  margin-bottom: 3rem;
  border-bottom: 3px ${Colors.strongOrange} solid;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    margin-bottom: 2rem;
  }
`;

export const SurveyArea = styled.div`
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: auto;
    margin: 0 1.5rem;
  }
`;
export const PicArea = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    display: none;
  }
`;

export const Image = styled.img`
  display: block;
  width: 80%;
  max-width: 50rem;
`;

export const SurveyButtons = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  width: 70%;
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 17rem;
  }
`;

interface ICurrentChoice {
  isCurrentChoice: boolean;
}

export const ChoiceButton = styled(MainButton)<ICurrentChoice>`
  width: 6rem;
  font-size: 1.25rem;
  border: none;
  padding: 0.625rem;
  background-color: ${(props) =>
    props.isCurrentChoice ? Colors.strongOrange : {}};
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    font-size: 1rem;
    padding: 0.5rem;
    width: 10rem;
  }
`;

interface IDirButton {
  userAtFirstQuestion?: boolean;
  choiceSelected?: boolean;
}

export const DirectionButtons = styled.div<IDirButton>`
  margin-top: 8rem;
  width: 70%;
  display: flex;
  justify-content: ${(props) =>
    props.userAtFirstQuestion ? "space-between" : "flex-end"};
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    text-align: center;
    width: 20rem;
    margin-top: 3rem;
    display: flex;
  }
`;

export const BackButton = styled(MainButton)<IDirButton>`
  width: 10rem;
  font-size: 1.25rem;
  display: ${(props) => (props.userAtFirstQuestion ? "block" : "none")};
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 5.5rem;
  }
`;

export const FwdButton = styled(MainButton)<IDirButton>`
  width: 10rem;
  font-size: 1.25rem;
  display: ${(props) => (props.choiceSelected ? "block" : "none")};
  @media only screen and (max-width: ${ScreenSize.MOBILE}) {
    width: 5.5rem;
  }
`;
