//combining all reducers used in this app
import { combineReducers } from "redux";
import questionCounter from "./questionCounter";
import updateQuestionPoints from "./updateQuestionPoints";

const allReducers = combineReducers({
  questionCounter: questionCounter,
  updateQuestionPoints: updateQuestionPoints,
});

export default allReducers;
